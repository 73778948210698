import React from 'react'
import { navigate, graphql } from 'gatsby'
import { City, Select, SelectOption } from '@ecommerce/shared'
import ErrorPage from '../templates/ErrorPage'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'

type CitySelectProps = {
  text: string
  options: SelectOption[]
  onSelect: (val: string | number) => Promise<void>
}

const CitySelect = ({ text, options, onSelect }: CitySelectProps) => (
  <>
    <p>{text}</p>
    <Select placeholder="Selecciona una ciudad" options={options} onSelect={onSelect} />
  </>
)

const NotFoundPage = ({ data, pageContext }: PgPageProps) => {
  const { title, template } = data.allContentfulPgPage.edges[0].node

  const cityOptions = pageContext.cities.map((city: City) => ({ label: city.name, value: city.slug }))

  return (
    <ErrorPage
      title={title}
      image={template.image.file.url}
      leftContent={{
        title: template.title,
        text: template.subtitle,
        buttonComponent: (
          <CitySelect text={template.selectText} options={cityOptions} onSelect={(slug) => navigate(`/${slug}/`)} />
        ),
      }}
    />
  )
}

export default withPageTransition(NotFoundPage)

export const query = graphql`
  query MyQuery($page404Id: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $page404Id } }) {
      edges {
        node {
          id
          title
          template {
            ... on ContentfulTm404Error {
              id
              name
              title
              subtitle
              selectText
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
